@import "../../../src/scss/themes/basic/abstracts/custom_variables";
@import "_default/block_hero_home.scss";

.block_hero_home {
    background: linear-gradient(90deg, $color-primary 0%, $color-secondary 154.72%);

    .radial {
        background: radial-gradient(48.95% 87.18% at 67.27% 43.43%, $color-radial-one 0%, rgba($color-primary, 0) 100%);
        position: absolute;
        width: 2094px;
        height: 1240px;
        right: -110%;
        bottom: 0;
        opacity: 0.6;
        z-index: 0;

        @include breakpoint(xxlarge) {
            right: -25%;
        }
    }

    .heading-h1,
    p,
    span.font-custom {
        color: $color-white;
    }

    .heading-h1 {
        font-weight: $bold;
        margin-bottom: 20px;
    }

    span.font-custom {
        font-size: clamp($heading-custom-font-min, 4vw, $heading-custom-font-max);
        top: 8px;
        position: relative;
    }

    .image {
        @include breakpoint(small only) {
            left: 25vw;
            width: 100%;
        }

        @include breakpoint(medium only) {
            left: 60vw;
        }
    }

    .font-custom {
        line-height: 30px;

        @include breakpoint(xlarge) {
            line-height: 60px;
        }
    }
}
