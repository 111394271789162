//Theme variables
$theme-name: 'basic';

//Defaults
$default-size: 16px;

//Hard colors
$color-black: #000000;
$color-white: #FFFFFF;

//Colors
$color-primary: #340C46;
$color-secondary: #143A84;
$color-tertiary: #FDB940;
$color-quaternary: #7D2776;
$color-form-borders: #CCCCCC;
$color-menu-bg: rgba(65, 65, 65, 0.5);
$color-menu-dark: #555555;
$color-menu-light: #F0F0F0;
$color-menu-mobile-bg: #252525;
$color-error: #FF0000;
$color-radial-one: #A17EB5;

//tag colors
$color-tag-border: #C2B6C8;
$color-tag-blog: #0081BD;
$color-tag-blog-bg: #E6F2F8;
$color-tag-books: $color-quaternary;
$color-tag-books-bg: #F2E9F1;
$color-tag-customer: #F68C3D;
$color-tag-customer-bg: #FEF4EC;

//Text colors
$color-text: $color-black;
$color-text-light: #5E5E5E;

//Font variables
$mainfont: 'Poppins';
$subfont: 'GoldenHopes';

//Font weights
$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$extrabold: 800;
$black: 900;

//Headings
$heading-custom-font-max: ceil(($default-size * 5)); // ~80px
$heading-custom-font-min: ceil(($default-size * 2.5)); // ~40px
$heading-h1-max: ceil(($default-size * 4)); // ~64px
$heading-h1-min: ceil(($default-size * 2)); // ~32px
$heading-h2-max: ceil(($default-size * 3)); // ~48px
$heading-h2-min: ceil(($default-size * 2)); // ~32px
$heading-h3-max: ceil(($default-size * 2)); // ~32px
$heading-h3-min: ceil(($default-size * 1.5)); // ~24px
$heading-h4-max: ceil(($default-size * 1.5)); // ~24px
$heading-h4-min: ceil(($default-size * 1.125)); // ~18px
$heading-h5: ceil(($default-size * 1.125)); // ~18px
$heading-h6: ceil(($default-size * 1)); // ~16px

//Text
$font-l: ceil(($default-size * 1.125)); // ~17px
$font-m: ceil(($default-size * 1)); // ~16px
$font-s: ceil(($default-size * 0.875)); // ~14px
$font-xs: ceil(($default-size * 0.75)); // ~12px
$font-menu: ceil(($default-size * 1.125)); // ~18px
$font-form: ceil(($default-size * 0.75)); // ~12px

//Other text styles
$font-quote: ceil(($default-size * 1.625)); // ~26px

//Font settings
$text-settings: (
        'font-heading-h1': (
                font-family: $mainfont,
                font-size: clamp($heading-h1-min, 4vw, $heading-h1-max),
                font-weight: $semibold,
                line-height: 125%,
        ),
        'font-heading-h2': (
                font-family: $mainfont,
                font-size: clamp($heading-h2-min, 4vw, $heading-h2-max),
                font-weight: $semibold,
                line-height: 125%,
        ),
        'font-heading-h3': (
                font-family: $mainfont,
                font-size: clamp($heading-h3-min, 4vw, $heading-h3-max),
                font-weight: $semibold,
                line-height: 125%,
        ),
        'font-heading-h4': (
                font-family: $mainfont,
                font-size: clamp($heading-h4-min, 4vw, $heading-h4-max),
                font-weight: $semibold,
                line-height: 125%,
        ),
        'font-heading-h5': (
                font-family: $mainfont,
                font-size: $heading-h5,
                font-weight: $semibold,
                line-height: 125%,
        ),
        'font-heading-h6': (
                font-family: $mainfont,
                font-size: $heading-h6,
                font-weight: $semibold,
                line-height: 125%,
        ),
        'font-custom': (
                font-family: $subfont,
                font-weight: $regular
        ),
        'font-text-l': (
                font-family: $mainfont,
                font-size: $font-l,
                line-height: 25px,
        ),
        'font-text-m': (
                font-family: $mainfont,
                font-size: $font-m,
                line-height: 24px,
        ),
        'font-text-s': (
                font-family: $mainfont,
                font-size: $font-s,
                line-height: 150%,
        ),
        'font-quote': (
                font-family: $mainfont,
                font-size: $font-quote,
                line-height: 150%,
        )
);

;@import "sass-embedded-legacy-load-done:45";