@import "../../../../src/scss/vendor/foundation/foundation_settings";
@import "../../../../src/scss/abstracts/custom_variables";
@import "../../../../src/scss/abstracts/custom_mixins";

.block_hero_home {
    display: flex;
    overflow: hidden;

    @include breakpoint(medium down) {
        flex-direction: column;
    }

    @include breakpoint(xlarge) {
        min-height: 750px;
    }

    .grid-container {
        width: 100%;
    }

    .grid-x {
        height: 100%;
        position: relative;
        z-index: 1;
    }

    figure {
        z-index: 1;
    }

    .image:not(.isTransparent) {
        top: 0;
        display: block;

        figure {
            position: absolute;
            width: 100%;
            height: 100%;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
    }

    .content {
        padding: 64px 0;
    }

    .image {
        width: 50vw;
        position: absolute;
        bottom: 0;
        left: 50vw;

        img {
            width: auto;
        }

        @include breakpoint(small only) {
            &.isTransparent {
                display: none;
            }
        }
    }

    .button--primary {
        margin-top: 16px;
    }
}
;@import "sass-embedded-legacy-load-done:107";